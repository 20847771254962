import React from "react";
import Container from "../ui/Container";

export default function UnsubscribeErrorPage() {
  return (
    <Container>
      <div class="space-y-3">
        <h1 className="text-4xl">Could not unsubscribe</h1>
        <p>
          Something went wrong when trying to unsubscribe. Please contact me at{" "}
          <a className="text-brand-blue" href="mailto:emric.mansson@gmail.com">
            emric.mansson@gmail.com
          </a>{" "}
          and we'll get it sorted!
        </p>
      </div>
    </Container>
  );
}
